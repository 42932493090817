/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Button, Row, Tabs, Typography } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import {
    useGetBalancesQuery,
    useGetTokenPricesQuery,
    useStatsQuery,
} from 'api';
import {
    ActiveAccounts,
    AllStats,
    ChartPickerModal,
    EarnStats,
    ExportChartsModal,
    GasSponsored,
    Layout,
    NewAccounts,
    PieCharts,
    RevenuePerActiveUser,
    Sidebar,
    SwapRevenue,
    TxCount,
} from 'components';
import { OnrampStats } from 'components/dashboardV2/OnrampStats';
import { useModalController } from 'hooks/useModalController';
import { useMemo, useState } from 'react';
import type { $MixedElement } from 'types';
import {
    ETH_ADDRESS,
    GRAPH_ETH_ADDRESS,
    type UserBalances,
    addressToToken,
    bytesToNumber,
    ethBalances,
    formatUnits,
    skipList,
    tabItems,
} from 'utils';

type Tab =
    | 'newAccounts'
    | 'activeAccounts'
    | 'swapRevenue'
    | 'rpau'
    | 'txCount'
    | 'gasSponsored'
    | 'earnStats'
    | 'onrampStats'
    | 'pieCharts'
    | 'allStats';

export const DashboardV2 = (): $MixedElement => {
    const [tab, setTab] = useState<Tab>('newAccounts');
    const { data, isLoading } = useStatsQuery();
    const {
        data: { tokenPrices },
    } = useGetTokenPricesQuery();
    const { data: balances } = useGetBalancesQuery();

    const pickModalController = useModalController();
    const exportModalController = useModalController();
    const [checkedCharts, setCheckedCharts] = useState<
        Array<CheckboxValueType>
    >([]);

    const onTabChange = (key: string): void => {
        setTab(key as Tab);
    };

    const orderedData = useMemo(() => {
        const sortedDays = [...data.days].sort((a, b) => {
            return bytesToNumber(a.id) < bytesToNumber(b.id) ? -1 : 1;
        });
        const sortedWeeks = [...data.weeks].sort((a, b) => {
            return bytesToNumber(a.id) < bytesToNumber(b.id) ? -1 : 1;
        });
        const sortedMonths = [...data.months].sort((a, b) => {
            return bytesToNumber(a.id) < bytesToNumber(b.id) ? -1 : 1;
        });
        return {
            ...data,
            days: sortedDays,
            weeks: sortedWeeks,
            months: sortedMonths,
        };
    }, [data]);

    const orderedPrices = useMemo(() => {
        if (tokenPrices == null) {
            return null;
        }
        Object.keys(tokenPrices).forEach((key) => {
            const lowerKey = key.toLowerCase();
            if (lowerKey !== key) {
                tokenPrices[lowerKey] = tokenPrices[key];
                delete tokenPrices[key];
            }
        });
        return tokenPrices;
    }, [tokenPrices]);

    const [userBalances, userBalancesUSD] = useMemo(() => {
        if (!balances || !tokenPrices) {
            return [null, null];
        }

        const userBalances: UserBalances = {};
        balances.erc20Balances.forEach((balance) => {
            const token: string = balance.token.id.toLowerCase();
            const account: string = balance.account.id.toLowerCase();
            const isEth = token === GRAPH_ETH_ADDRESS;
            const amount: bigint = BigInt(balance.amount);

            if (skipList.includes(account)) {
                return;
            }

            const decimals = addressToToken[token]?.decimals ?? 18;
            userBalances[account] = userBalances[account] ?? {};

            if (isEth) {
                const previousBalance = ethBalances[account] ?? 0n;
                userBalances[account][ETH_ADDRESS] = formatUnits(
                    amount + previousBalance,
                    decimals,
                );
            } else {
                userBalances[account][token] = formatUnits(amount, decimals);
            }
        });

        const userBalancesUSD: UserBalances = {};
        Object.entries(userBalances).forEach(([address, balances]) => {
            userBalancesUSD[address] = {};
            Object.entries(balances).forEach(([token, balance]) => {
                const price = tokenPrices[token] ?? 0;
                userBalancesUSD[address][token] = balance * price;
            });
        });

        return [userBalances, userBalancesUSD];
    }, [balances, tokenPrices]);

    return (
        <Row wrap={false}>
            <ChartPickerModal
                modalController={pickModalController}
                exportModalController={exportModalController}
                setCharts={setCheckedCharts}
            />
            <ExportChartsModal
                modalController={exportModalController}
                charts={checkedCharts}
                data={orderedData}
                isLoading={isLoading}
                tokenPrices={orderedPrices}
            />
            <Sidebar />
            <Layout>
                <Row className="items-center" justify="space-between">
                    <Typography.Title className="mb-0" level={1}>
                        Clave Data Dashboard
                    </Typography.Title>
                    <Button
                        type="primary"
                        onClick={(): void => {
                            pickModalController.open();
                        }}
                    >
                        Export Charts
                    </Button>
                </Row>
                <>
                    <Tabs onChange={onTabChange} items={tabItems} />
                    {tab === 'newAccounts' ? (
                        <NewAccounts data={orderedData} isLoading={isLoading} />
                    ) : tab === 'activeAccounts' ? (
                        <ActiveAccounts
                            data={orderedData}
                            isLoading={isLoading}
                        />
                    ) : tab === 'swapRevenue' ? (
                        <SwapRevenue
                            data={orderedData}
                            isLoading={isLoading}
                            tokenPrices={orderedPrices}
                        />
                    ) : tab === 'rpau' ? (
                        <RevenuePerActiveUser
                            data={orderedData}
                            isLoading={isLoading}
                            tokenPrices={orderedPrices}
                        />
                    ) : tab === 'txCount' ? (
                        <TxCount data={orderedData} isLoading={isLoading} />
                    ) : tab === 'gasSponsored' ? (
                        <GasSponsored
                            data={orderedData}
                            isLoading={isLoading}
                            tokenPrices={orderedPrices}
                        />
                    ) : tab === 'earnStats' ? (
                        <EarnStats
                            data={orderedData}
                            isLoading={isLoading}
                            tokenPrices={orderedPrices}
                        />
                    ) : tab === 'onrampStats' ? (
                        <OnrampStats />
                    ) : tab === 'pieCharts' ? (
                        <PieCharts
                            data={orderedData}
                            isLoading={isLoading}
                            balancesUSD={userBalancesUSD}
                            tokenPrices={orderedPrices}
                        />
                    ) : (
                        <AllStats
                            tokenPrices={orderedPrices}
                            balancesUSD={userBalancesUSD}
                            balances={userBalances}
                        />
                    )}
                </>
            </Layout>
        </Row>
    );
};
