/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { type TableColumnsType, type TabsProps } from 'antd';

export enum Charts {
    NewAccounts = 'newAccounts',
    ActiveAccounts = 'activeAccounts',
    GasSponsored = 'gasSponsored',
    EarnStats = 'earnStats',
    OnrampStats = 'onrampStats',
    SwapRevenue = 'swapRevenue',
    TxCount = 'txCount',
}

export const tabItems: TabsProps['items'] = [
    {
        key: 'newAccounts',
        label: 'New Accounts',
        children: null,
    },
    {
        key: 'activeAccounts',
        label: 'Active Accounts',
        children: null,
    },
    {
        key: 'swapRevenue',
        label: 'Swap Revenue',
        children: null,
    },
    {
        key: 'rpau',
        label: 'RPAU',
        children: null,
    },
    {
        key: 'txCount',
        label: 'Tx Count',
        children: null,
    },
    {
        key: 'gasSponsored',
        label: 'Gas Sponsored',
        children: null,
    },
    {
        key: 'earnStats',
        label: 'Earn Stats',
        children: null,
    },
    {
        key: 'onrampStats',
        label: 'Onramp Stats',
        children: null,
    },
    {
        key: 'pieCharts',
        label: 'Pie Charts',
        children: null,
    },
    {
        key: 'allStats',
        label: 'All Stats',
        children: null,
    },
];

export interface IUser {
    username: string;
    key: string;
    email: string;
    countryCode: string;
    device: string;
    referrer: string;
    totalPortfolio: string;
    idlePortfolio: string;
    swapRevenue: string;
    hasBackup: string;
    campaignStatus: string;
    txCount: JSX.Element;
    earnPositions: JSX.Element;
    realizedGain: JSX.Element;
    deposit: JSX.Element;
    onramp: JSX.Element;
    referralGain: JSX.Element;
    refCount: number;
    creationDate: string;
    activeDays: number;
    activeWeeks: number;
    activeMonths: number;
    earn: number;
    paymentLinks: number;
    swap: number;
    transfer: number;
    upgraded: string;
}

export const UserColumns: TableColumnsType<IUser> = [
    {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
    },
    {
        title: 'Address',
        dataIndex: 'key',
        key: 'key',
    },
    {
        title: 'Device',
        dataIndex: 'device',
        key: 'device',
    },
    {
        title: 'Country Code',
        dataIndex: 'countryCode',
        key: 'countryCode',
    },
    {
        title: 'Referrer',
        dataIndex: 'referrer',
        key: 'referrer',
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Total Portfolio',
        dataIndex: 'totalPortfolio',
        key: 'totalPortfolio',
        sorter: {
            compare: (a, b) =>
                parseFloat(a.totalPortfolio.slice(1)) -
                parseFloat(b.totalPortfolio.slice(1)),
        },
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Idle Portfolio',
        dataIndex: 'idlePortfolio',
        key: 'idlePortfolio',
        sorter: {
            compare: (a, b) =>
                parseFloat(a.idlePortfolio.slice(1)) -
                parseFloat(b.idlePortfolio.slice(1)),
        },
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Swap Revenue',
        dataIndex: 'swapRevenue',
        key: 'swapRevenue',
        sorter: {
            compare: (a, b) =>
                parseFloat(a.swapRevenue.slice(1)) -
                parseFloat(b.swapRevenue.slice(1)),
        },
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Tx Count',
        dataIndex: 'txCount',
        key: 'txCount',
        sorter: {
            compare: (a, b) => Number(a.txCount.key) - Number(b.txCount.key),
        },
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Earn Positions',
        dataIndex: 'earnPositions',
        key: 'earnPositions',
        sorter: {
            compare: (a, b) =>
                Number(a.earnPositions.key) - Number(b.earnPositions.key),
        },
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Realized Gain',
        dataIndex: 'realizedGain',
        key: 'realizedGain',
        sorter: {
            compare: (a, b) =>
                Number(a.realizedGain.key) - Number(b.realizedGain.key),
        },
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Deposit',
        dataIndex: 'deposit',
        key: 'deposit',
        sorter: {
            compare: (a, b) => Number(a.deposit.key) - Number(b.deposit.key),
        },
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Onramp',
        dataIndex: 'onramp',
        key: 'onramp',
        sorter: {
            compare: (a, b) => Number(a.onramp.key) - Number(b.onramp.key),
        },
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Referral Gain',
        dataIndex: 'referralGain',
        key: 'referralGain',
        sorter: {
            compare: (a, b) =>
                Number(a.referralGain.key) - Number(b.referralGain.key),
        },
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Referree Count',
        dataIndex: 'refCount',
        key: 'refCount',
        sorter: {
            compare: (a, b) => a.refCount - b.refCount,
        },
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Has Backup',
        dataIndex: 'hasBackup',
        key: 'hasBackup',
    },
    {
        title: 'Campaign Status',
        dataIndex: 'campaignStatus',
        key: 'campaignStatus',
    },
    {
        title: 'Active Days',
        dataIndex: 'activeDays',
        key: 'activeDays',
        sorter: {
            compare: (a, b) => a.activeDays - b.activeDays,
        },
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Active Weeks',
        dataIndex: 'activeWeeks',
        key: 'activeWeeks',
        sorter: {
            compare: (a, b) => a.activeWeeks - b.activeWeeks,
        },
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Active Months',
        dataIndex: 'activeMonths',
        key: 'activeMonths',
        sorter: {
            compare: (a, b) => a.activeMonths - b.activeMonths,
        },
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Payment Links',
        dataIndex: 'paymentLinks',
        key: 'paymentLinks',
        sorter: {
            compare: (a, b) => (a.paymentLinks ?? 0) - (b.paymentLinks ?? 0),
        },
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Swap',
        dataIndex: 'swap',
        key: 'swap',
        sorter: {
            compare: (a, b) => (a.swap ?? 0) - (b.swap ?? 0),
        },
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Transfer',
        dataIndex: 'transfer',
        key: 'transfer',
        sorter: {
            compare: (a, b) => (a.transfer ?? 0) - (b.transfer ?? 0),
        },
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Earn',
        dataIndex: 'earn',
        key: 'earn',
        sorter: {
            compare: (a, b) => (a.earn ?? 0) - (b.earn ?? 0),
        },
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Creation Date',
        dataIndex: 'creationDate',
        key: 'creationDate',
    },
    {
        title: 'Upgraded',
        dataIndex: 'upgraded',
        key: 'upgraded',
    },
];
